const BenefitSectionData = [
    {
      id: 1,
      heading: "Certificate of Attendance",
      textBody: "Certification will be awarded at completion of the 3 weeks boot-camp as proof to show participation.",
      clipColor: "rgba(253, 126, 20, 0.80)",
    },
    {
      id: 2,
      heading: "Access to In-demand Video Tutorials",
      textBody: "These tutorials are designed to deepen your knowledge, enhance your skills, and keep you ahead in your field",
      clipColor: "rgba(40, 167, 69, 0.50)",
    },
    {
      id: 3,
      heading: "Lunch Provided",
      textBody: "Enjoy complimentary lunch every day during our 3-week bootcamp. We believe that a well-fed mind is a productive mind.",
      clipColor: "rgba(0, 123, 255, 0.40)",
    },
    {
      id: 4,
      heading: "100k Cash Prize Up for Grabs",
      textBody: "Cash rewards for top three best performing participant during the boot camp.",
      clipColor: "rgba(40, 167, 69, 0.80)",
    },
    {
      id: 5,
      heading: "Compete With Peers in Game Tournaments.",
      textBody: "Compete with peers in chess tournament and be named champion. This event allows you to showcase your strategy and problem solving skills.",
      clipColor: "var(--primary-colour)",
    },
    {
      id: 6,
      heading: "Become Part of a Community of Nextgeners",
      textBody: "Connect with peers who are equally passionate about innovation and growth. Collaborate, network, and build lasting relationships with the next generation of leaders and visionaries.",
      clipColor: "rgba(253, 126, 20, 0.40)",
    },
    {
      id: 7,
      heading: "Advanced Training for Top Participants.",
      textBody: "Stand out during our 3-week bootcamp and earn the opportunity for advanced training. ",
      clipColor: "rgba(255, 119, 6, 0.60)",
    },
  ];
  
  export default BenefitSectionData;
  